import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import * as XLSX from 'xlsx';

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length;

    return (
        <span>
            Search:{' '}
            <input
                value={globalFilter || ''}
                onChange={e => {
                    setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                placeholder={`${count} records...`}
                className="border rounded py-1 px-2"
            />
        </span>
    );
}

const ProcessedCOs = () => {
    const [cos, setCos] = useState([]);

    useEffect(() => {
        const fetchCOs = async () => {
            try {
                const res = await axios.get('https://iplus.dashboard.connectstuff.nl/api/processed-cos');
                setCos(res.data);
            } catch (err) {
                console.error(err);
            }
        };

        fetchCOs();
    }, []);

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString();
    };

    const getStatusIcon = (status) => {
        return status === 'SUCCESS' ? '✅' : '❌';
    };

    const columns = React.useMemo(() => [
        {
            Header: 'Identifier',
            accessor: 'co_number',
            width: 150,
        },
        {
            Header: 'Last Modified Date',
            accessor: 'last_modified_date',
            Cell: ({ value }) => formatTimestamp(value),
            width: 200,
        },
        {
            Header: 'Environment',
            accessor: 'environment',
            width: 100,
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => getStatusIcon(value),
            width: 100,
        },
        {
            Header: 'Remarks',
            accessor: 'remarks',
        },
    ], []);

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(cos);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Processed COs");
        XLSX.writeFile(workbook, "processed_cos.xlsx");
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page, // Instead of using rows, we'll use page
        prepareRow,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data: cos,
            initialState: { pageIndex: 0, pageSize: 10 }, // Pass our hoisted table state
        },
        useGlobalFilter,
        useSortBy,
        usePagination // Use the usePagination hook
    );

    return (
        <div className="table-container">
            <h1 className="text-2xl font-bold mb-4">Processed COs</h1>
            <div className="mb-4 flex justify-between items-center">
                <button onClick={exportToExcel} className="bg-blue-500 text-white px-4 py-2 rounded">Export Excel</button>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
            </div>
            <table {...getTableProps()} className="min-w-full bg-white border rounded shadow">
                <thead className="bg-gray-200">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} className="py-2 px-4 text-left border-b">
                                    <div className="flex items-center">
                                        <span>
                                            {column.render('Header')}
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} className="hover:bg-gray-100">
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()} className="border px-4 py-2">
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="pagination mt-4 flex justify-between items-center">
                <div>
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="px-3 py-1 border rounded mr-2 bg-blue-500 text-white disabled:opacity-50">
                        {'<<'}
                    </button>
                    <button onClick={() => previousPage()} disabled={!canPreviousPage} className="px-3 py-1 border rounded mr-2 bg-blue-500 text-white disabled:opacity-50">
                        {'<'}
                    </button>
                    <button onClick={() => nextPage()} disabled={!canNextPage} className="px-3 py-1 border rounded mr-2 bg-blue-500 text-white disabled:opacity-50">
                        {'>'}
                    </button>
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="px-3 py-1 border rounded bg-blue-500 text-white disabled:opacity-50">
                        {'>>'}
                    </button>
                </div>
                <div>
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    <span>
                        | Go to page:{' '}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(page);
                            }}
                            className="border p-1 rounded w-16 text-center"
                        />
                    </span>
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value));
                        }}
                        className="border p-1 rounded ml-2"
                    >
                        {[10, 15, 20, 25, 30, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default ProcessedCOs;
