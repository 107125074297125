import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import DataTable from 'react-data-table-component';

const Logs = () => {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        const fetchLogs = async () => {
            try {
                const response = await axios.get(`${config.apiBaseUrl}/logs`);
                setLogs(response.data);
            } catch (error) {
                console.error('Error fetching logs', error);
            }
        };

        fetchLogs();
    }, []);

    const columns = [
        { name: 'Timestamp', selector: 'timestamp', sortable: true },
        { name: 'Level', selector: 'level', sortable: true },
        { name: 'Message', selector: 'message', sortable: true },
        { name: 'Exception', selector: 'exception', sortable: true },
        { name: 'Identifier', selector: 'identifier', sortable: true },
    ];

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4">Logs</h1>
            <DataTable
                columns={columns}
                data={logs}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                defaultSortField="timestamp"
                defaultSortAsc={false}
                highlightOnHover
                striped
            />
        </div>
    );
};

export default Logs;
