import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import * as XLSX from 'xlsx';
import Header from './Header';
import ProcessedPOs from './ProcessedPOs'; // Importeer het nieuwe component

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length;

    return (
        <span>
            Search:{' '}
            <input
                value={globalFilter || ''}
                onChange={e => {
                    setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                placeholder={`${count} records...`}
                className="border rounded py-1 px-2"
            />
        </span>
    );
}

const PO = () => {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        const fetchLogs = async () => {
            try {
                const res = await axios.get('https://iplus.dashboard.connectstuff.nl/api/po-logs');
                setLogs(res.data.filter(log => log.exception !== "Duplicate entry"));
            } catch (err) {
                console.error(err);
            }
        };

        fetchLogs();
    }, []);

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString();
    };

    const getLevelIcon = (level) => {
        switch (level) {
            case 'INFO':
                return 'ℹ️';
            case 'ERROR':
                return '❌';
            case 'SUCCESS':
                return '✅';
            default:
                return '';
        }
    };

    const getEnvironment = (message) => {
        const lowerCaseMessage = message.toLowerCase();
        if (lowerCaseMessage.includes('gdf')) {
            return 'GDF';
        }
        if (lowerCaseMessage.includes('iplus')) {
            return 'IPLUS';
        }
        return 'Unknown';
    };

    const formatException = (exception) => {
        try {
            const exceptionObj = JSON.parse(exception);
            return (
                <div className="p-2 bg-gray-100 border border-gray-300 rounded">
                    {exceptionObj.externalMessage && <p><strong>Message:</strong> {exceptionObj.externalMessage}</p>}
                    {exceptionObj.errorNumber && <p><strong>Error Number:</strong> {exceptionObj.errorNumber}</p>}
                    {exceptionObj.profitLogReference && <p><strong>Log Reference:</strong> {exceptionObj.profitLogReference}</p>}
                </div>
            );
        } catch (e) {
            return <span>{exception}</span>; // Return the raw exception if it cannot be parsed
        }
    };

    const exportToExcel = (data, sheetName, fileName) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        XLSX.writeFile(workbook, fileName);
    };

    const columns = React.useMemo(() => [
        {
            Header: 'Identifier',
            accessor: 'identifier',
            width: 150,
        },
        {
            Header: 'Timestamp',
            accessor: 'timestamp',
            Cell: ({ value }) => formatTimestamp(value),
            width: 200,
        },
        {
            Header: 'Level',
            accessor: 'level',
            Cell: ({ value }) => getLevelIcon(value),
            width: 100,
        },
        {
            Header: 'Environment',
            accessor: 'message',
            Cell: ({ value }) => getEnvironment(value),
            width: 150,
        },
        {
            Header: 'Exception',
            accessor: 'exception',
            Cell: ({ value }) => formatException(value),
        },
    ], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page, // Instead of using rows, we'll use page
        prepareRow,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data: logs,
            initialState: { pageIndex: 0, pageSize: 10 }, // Pass our hoisted table state
        },
        useGlobalFilter,
        useSortBy,
        usePagination // Use the usePagination hook
    );

    return (
        <>
            <Header />
            <div className="container mx-auto p-4">
                <h1 className="text-3xl font-bold mb-4">PO Dashboard</h1>
                <p className="mb-6 text-gray-700">
                    Welcome to the PO Dashboard. Here you can monitor the status of your Purchase Orders (POs).
                    You can see logs of all processed POs, filter and sort the data, and export the logs to Excel for further analysis.
                </p>
                <div className="table-container mb-8">
                    <ProcessedPOs /> {/* Voeg het nieuwe component toe */}
                </div>
                <h1 className="text-2xl font-bold mb-4">PO Logs</h1>
                <div className="mb-4 flex justify-between items-center">
                    <button onClick={() => exportToExcel(logs, "Logs", "logs.xlsx")} className="bg-blue-500 text-white px-4 py-2 rounded">Export Excel</button>
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                </div>
                <table {...getTableProps()} className="min-w-full bg-white border rounded shadow">
                    <thead className="bg-gray-200">
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className="py-2 px-4 text-left border-b">
                                        <div className="flex items-center">
                                            <span>
                                                {column.render('Header')}
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? ' 🔽'
                                                        : ' 🔼'
                                                    : ''}
                                            </span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className="hover:bg-gray-100">
                                    {row.cells.map(cell => (
                                        <td {...cell.getCellProps()} className="border px-4 py-2">
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="pagination mt-4 flex justify-between items-center">
                    <div>
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="px-3 py-1 border rounded mr-2 bg-blue-500 text-white disabled:opacity-50">
                            {'<<'}
                        </button>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage} className="px-3 py-1 border rounded mr-2 bg-blue-500 text-white disabled:opacity-50">
                            {'<'}
                        </button>
                        <button onClick={() => nextPage()} disabled={!canNextPage} className="px-3 py-1 border rounded mr-2 bg-blue-500 text-white disabled:opacity-50">
                            {'>'}
                        </button>
                        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="px-3 py-1 border rounded bg-blue-500 text-white disabled:opacity-50">
                            {'>>'}
                        </button>
                    </div>
                    <div>
                        <span>
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </span>
                        <span>
                            | Go to page:{' '}
                            <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                    gotoPage(page);
                                }}
                                className="border p-1 rounded w-16 text-center"
                            />
                        </span>
                        <select
                            value={pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value));
                            }}
                            className="border p-1 rounded ml-2"
                        >
                            {[10, 15, 20, 25, 30, 50, 100].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PO;
