import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import CO from './components/CO';
import PO from './components/PO';
import CollectionDates from './components/CollectionDates';
import ProcessedCOs from './components/ProcessedCOs';
import ProcessedPOs from './components/ProcessedPOs';
import ProcessedCollections from './components/ProcessedCollections';
import Logs from './components/Logs';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import Footer from './components/Footer';
import Header from './components/Header';

function App() {
  return (
    <Router>
      <div className="App flex flex-col min-h-screen">
        <div className="flex-grow">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/logs" element={<ProtectedRoute element={<Logs />} />} />
            <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
            <Route path="/po" element={<ProtectedRoute element={<PO />} />} />
            <Route path="/co" element={<ProtectedRoute element={<CO />} />} />
            <Route path="/collection-dates" element={<ProtectedRoute element={<CollectionDates />} />} />
            <Route path="/processed-cos" element={<ProtectedRoute element={<ProcessedCOs />} />} />
            <Route path="/processed-pos" element={<ProtectedRoute element={<ProcessedPOs />} />} />
            <Route path="/processed-collections" element={<ProtectedRoute element={<ProcessedCollections />} />} />
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
