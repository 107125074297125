import React from 'react';
import logo from '../assets/FF LOGO V3.png'; // Update this path if needed

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white py-4 mt-8">
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
                <div className="flex items-center space-x-4">
                    <img src={logo} alt="ConnectStuff Logo" className="h-8" />
                    <span>&copy; {new Date().getFullYear()} ConnectStuff</span>
                </div>
                <div className="text-center md:text-right mt-4 md:mt-0">
                    <p>
                        <a href="https://connectstuff.nl" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">https://connectstuff.nl</a><br />
                        <a href="mailto:support@connectstuff.nl" className="text-gray-400 hover:text-white">support@connectstuff.nl</a><br />
                        <a href="tel:+31850805248" className="text-gray-400 hover:text-white">085 0805248</a>
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
