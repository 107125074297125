import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import { useSpring, animated } from '@react-spring/web';
import Header from './Header';

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const Home = () => {
    const [poData, setPoData] = useState([]);
    const [coData, setCoData] = useState([]);
    const [collectionData, setCollectionData] = useState([]);
    const [poTotals, setPoTotals] = useState({ totalSuccess: 0, totalError: 0 });
    const [coTotals, setCoTotals] = useState({ totalSuccess: 0, totalError: 0 });
    const [collectionTotals, setCollectionTotals] = useState({ totalSuccess: 0, totalError: 0 });
    const [lastPoRun, setLastPoRun] = useState(null);
    const [lastCoRun, setLastCoRun] = useState(null);
    const [lastCollectionRun, setLastCollectionRun] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPoData = async () => {
            const res = await axios.get('https://iplus.dashboard.connectstuff.nl/api/po-logs-per-day');
            setPoData(res.data);
            const totals = await axios.get('https://iplus.dashboard.connectstuff.nl/api/po-log-totals');
            setPoTotals(totals.data);
            const lastRun = await axios.get('https://iplus.dashboard.connectstuff.nl/api/last-po-run');
            setLastPoRun(lastRun.data);
        };

        const fetchCoData = async () => {
            const res = await axios.get('https://iplus.dashboard.connectstuff.nl/api/co-logs-per-day');
            setCoData(res.data);
            const totals = await axios.get('https://iplus.dashboard.connectstuff.nl/api/co-log-totals');
            setCoTotals(totals.data);
            const lastRun = await axios.get('https://iplus.dashboard.connectstuff.nl/api/last-co-run');
            setLastCoRun(lastRun.data);
        };

        const fetchCollectionData = async () => {
            const res = await axios.get('https://iplus.dashboard.connectstuff.nl/api/collection-logs-per-day');
            setCollectionData(res.data);
            const totals = await axios.get('https://iplus.dashboard.connectstuff.nl/api/collection-log-totals');
            setCollectionTotals(totals.data);
            const lastRun = await axios.get('https://iplus.dashboard.connectstuff.nl/api/last-collection-run');
            setLastCollectionRun(lastRun.data);
        };

        Promise.all([fetchPoData(), fetchCoData(), fetchCollectionData()]).then(() => {
            setLoading(false);
        });
    }, []);

    const formatChartData = (data) => {
        if (!Array.isArray(data)) {
            return {
                labels: [],
                datasets: [],
            };
        }

        return {
            labels: data.map(item => item.date),
            datasets: [
                {
                    label: 'Success',
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(75, 192, 192, 0.8)',
                    hoverBorderColor: 'rgba(75, 192, 192, 1)',
                    data: data.map(item => item.success),
                },
                {
                    label: 'Error',
                    backgroundColor: 'rgba(255, 99, 132, 0.6)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(255, 99, 132, 0.8)',
                    hoverBorderColor: 'rgba(255, 99, 132, 1)',
                    data: data.map(item => item.error),
                },
            ],
        };
    };

    const kpiSpring = useSpring({
        from: { opacity: 0, transform: 'translateY(20px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        delay: 500,
    });

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString('nl-NL', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Header />
            <div className="container mx-auto p-4">
                <animated.h1 style={kpiSpring} className="text-4xl font-bold text-center mb-4">
                    Welcome to the Analytics Dashboard
                </animated.h1>
                <p className="text-center text-lg mb-8">
                    Here you can find an overview of all your PO, CO, and Collection logs. Use the data to gain insights into the success and failure rates of your processes.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
                    <animated.div style={kpiSpring} className="bg-white p-4 rounded shadow text-center">
                        <h2 className="text-2xl font-bold">PO Logs Success</h2>
                        <p className="text-4xl mt-2 text-green-600">{poTotals.totalSuccess}</p>
                    </animated.div>
                    <animated.div style={kpiSpring} className="bg-white p-4 rounded shadow text-center">
                        <h2 className="text-2xl font-bold">CO Logs Success</h2>
                        <p className="text-4xl mt-2 text-green-600">{coTotals.totalSuccess}</p>
                    </animated.div>
                    <animated.div style={kpiSpring} className="bg-white p-4 rounded shadow text-center">
                        <h2 className="text-2xl font-bold">Collection Logs Success</h2>
                        <p className="text-4xl mt-2 text-green-600">{collectionTotals.totalSuccess}</p>
                    </animated.div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
                    <animated.div style={kpiSpring} className="bg-white p-4 rounded shadow text-center">
                        <h2 className="text-2xl font-bold">PO Logs Errors</h2>
                        <p className="text-4xl mt-2 text-red-600">{poTotals.totalError}</p>
                    </animated.div>
                    <animated.div style={kpiSpring} className="bg-white p-4 rounded shadow text-center">
                        <h2 className="text-2xl font-bold">CO Logs Errors</h2>
                        <p className="text-4xl mt-2 text-red-600">{coTotals.totalError}</p>
                    </animated.div>
                    <animated.div style={kpiSpring} className="bg-white p-4 rounded shadow text-center">
                        <h2 className="text-2xl font-bold">Collection Logs Errors</h2>
                        <p className="text-4xl mt-2 text-red-600">{collectionTotals.totalError}</p>
                    </animated.div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
                    <div className="bg-white p-4 rounded shadow text-center">
                        <h2 className="text-xl font-bold mb-4">Last PO Run</h2>
                        {lastPoRun ? (
                            <div>
                                <p className="text-2xl text-gray-700">{formatDate(lastPoRun.timestamp)}</p>
                            </div>
                        ) : (
                            <p>No data available</p>
                        )}
                    </div>
                    <div className="bg-white p-4 rounded shadow text-center">
                        <h2 className="text-xl font-bold mb-4">Last CO Run</h2>
                        {lastCoRun ? (
                            <div>
                                <p className="text-2xl text-gray-700">{formatDate(lastCoRun.timestamp)}</p>
                            </div>
                        ) : (
                            <p>No data available</p>
                        )}
                    </div>
                    <div className="bg-white p-4 rounded shadow text-center">
                        <h2 className="text-xl font-bold mb-4">Last Collection Run</h2>
                        {lastCollectionRun ? (
                            <div>
                                <p className="text-2xl text-gray-700">{formatDate(lastCollectionRun.timestamp)}</p>
                            </div>
                        ) : (
                            <p>No data available</p>
                        )}
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="bg-white p-4 rounded shadow">
                        <h2 className="text-xl font-bold mb-4 text-center">PO Logs</h2>
                        <div style={{ height: '300px' }}>
                            <Bar data={formatChartData(poData)} options={{ responsive: true, maintainAspectRatio: false }} />
                        </div>
                    </div>
                    <div className="bg-white p-4 rounded shadow">
                        <h2 className="text-xl font-bold mb-4 text-center">CO Logs</h2>
                        <div style={{ height: '300px' }}>
                            <Bar data={formatChartData(coData)} options={{ responsive: true, maintainAspectRatio: false }} />
                        </div>
                    </div>
                    <div className="bg-white p-4 rounded shadow">
                        <h2 className="text-xl font-bold mb-4 text-center">Collection Logs</h2>
                        <div style={{ height: '300px' }}>
                            <Bar data={formatChartData(collectionData)} options={{ responsive: true, maintainAspectRatio: false }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
