import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../assets/FF LOGO V3.png'; // Update dit met het correcte pad naar je logo

const Header = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        navigate('/login');
    };

    return (
        <nav className="bg-gray-800 p-4 shadow-md">
            <div className="container mx-auto flex justify-between items-center">
                <div className="flex items-center space-x-6">
                    <img src={logo} alt="ConnectStuff Logo" className="h-8" />
                    <NavLink
                        to="/"
                        className={({ isActive }) =>
                            isActive ? 'text-white font-bold' : 'text-gray-400'
                        }
                        end // Dit zorgt ervoor dat exact pad wordt gematched
                    >
                        Home
                    </NavLink>
                    <NavLink
                        to="/collection-dates"
                        className={({ isActive }) =>
                            isActive ? 'text-white font-bold' : 'text-gray-400'
                        }
                    >
                        Collection Dates
                    </NavLink>
                    <NavLink
                        to="/po"
                        className={({ isActive }) =>
                            isActive ? 'text-white font-bold' : 'text-gray-400'
                        }
                    >
                        PO
                    </NavLink>
                    <NavLink
                        to="/co"
                        className={({ isActive }) =>
                            isActive ? 'text-white font-bold' : 'text-gray-400'
                        }
                    >
                        CO
                    </NavLink>
                </div>
                <button onClick={handleLogout} className="text-gray-400 hover:text-white">Logout</button>
            </div>
        </nav>
    );
};

export default Header;
